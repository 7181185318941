import { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../config/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import Title from "../../components/title";
import CollapsibleTable from "../../components/collapsible-table";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import DialogUi from "../../components/dialog";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InventoryUpload from "../inventory-upload";

// Interface para representar um item de inventário
interface InventoryItem {
  id: string;
  name: string;
  amountOfItems: number;
  costPerItem: number;
  pricePerItem: number;
}

// Estado inicial para um novo item de inventário
const initialInventoryState: InventoryItem = {
  id: "",
  name: "",
  amountOfItems: 0,
  costPerItem: 0,
  pricePerItem: 0,
};

const InventoryList = () => {
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [currentInventoryItem, setCurrentInventoryItem] =
    useState<InventoryItem>(initialInventoryState);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const inventoryCollectionRef = collection(db, "inventory");

  const handleClickOpenCreate = () => setOpenCreate(true);
  const handleClickOpenEdit = (item: InventoryItem) => {
    setOpenEdit(true);
    setCurrentInventoryItem(item);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
    setCurrentInventoryItem(initialInventoryState);
  };

  const handleEdit = async () => {
    await editInventoryItem(currentInventoryItem.id);
    handleClose();
  };

  const handleSuccess = async () => {
    await createInventoryItem();
    handleClose();
  };

  const createInventoryItem = async () => {
    await addDoc(inventoryCollectionRef, {
      name: currentInventoryItem.name,
      amountOfItems: currentInventoryItem.amountOfItems,
      costPerItem: currentInventoryItem.costPerItem,
      pricePerItem: currentInventoryItem.pricePerItem,
    });
    fetchInventory();
  };

  const editInventoryItem = async (id: string) => {
    const inventoryDoc = doc(db, "inventory", id);
    await updateDoc(inventoryDoc, {
      name: currentInventoryItem.name,
      amountOfItems: currentInventoryItem.amountOfItems,
      costPerItem: currentInventoryItem.costPerItem,
      pricePerItem: currentInventoryItem.pricePerItem,
    });
    fetchInventory();
  };

  const deleteInventoryItem = async (id: string) => {
    const inventoryDoc = doc(db, "inventory", id);
    await deleteDoc(inventoryDoc);
    fetchInventory();
  };

  const fetchInventory = async () => {
    const data = await getDocs(inventoryCollectionRef);
    setInventory(
      data.docs.map((doc) => ({ ...doc.data(), id: doc.id } as InventoryItem))
    );
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  const renderInventoryForm = () => (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
        display: "flex",
        flexDirection: "column",
        width: "500px",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label="Name"
        variant="outlined"
        value={currentInventoryItem.name}
        onChange={(e) =>
          setCurrentInventoryItem({
            ...currentInventoryItem,
            name: e.target.value,
          })
        }
      />
      <TextField
        label="Amount of Items"
        variant="outlined"
        type="number"
        value={currentInventoryItem.amountOfItems}
        onChange={(e) =>
          setCurrentInventoryItem({
            ...currentInventoryItem,
            amountOfItems: parseInt(e.target.value),
          })
        }
      />
      <TextField
        label="Cost per Item"
        variant="outlined"
        type="text"
        value={currentInventoryItem.costPerItem}
        onChange={(e) =>
          setCurrentInventoryItem({
            ...currentInventoryItem,
            costPerItem: parseFloat(e.target.value),
          })
        }
      />
      <TextField
        label="Price per Item"
        variant="outlined"
        type="text"
        value={currentInventoryItem.pricePerItem}
        onChange={(e) =>
          setCurrentInventoryItem({
            ...currentInventoryItem,
            pricePerItem: parseFloat(e.target.value),
          })
        }
      />
    </Box>
  );

  return (
    <Box sx={{ padding: "48px", paddingTop: "160px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "16px",
        }}
      >
        <Title>Inventory</Title>
        <InventoryUpload onSuccess={fetchInventory} />
        {/*<Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenCreate}
        >
          <AddIcon /> Add Inventory Item
        </Button>*/}
      </Box>
      <DialogUi
        open={openCreate}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        component={renderInventoryForm()}
        title="Add New Inventory Item"
        buttonLabel="Add"
      />
      <DialogUi
        open={openEdit}
        handleClose={handleClose}
        handleSuccess={handleEdit}
        component={renderInventoryForm()}
        title="Edit Inventory Item"
        buttonLabel="Save"
      />
      <CollapsibleTable
        header={[
          "Name",
          "Amount of Items",
          "Cost per Item",
          "Price per Item",
          "",
          "",
        ]}
        rows={inventory.map((item) => ({
          cells: [
            item.name,
            item.amountOfItems.toString(),
            "£ " + item.costPerItem.toString(),
            "£ " + item.pricePerItem.toString(),
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleClickOpenEdit(item)}
            >
              Edit
            </Button>,
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => deleteInventoryItem(item.id)}
            >
              Delete
            </Button>,
          ],
        }))}
      />
    </Box>
  );
};

export default InventoryList;
