import { useState, useEffect } from "react";
import "../../App.css";
import { db, storage } from "../../config/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Title from "../../components/title";
import CollapsibleTable from "../../components/collapsible-table";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TagColor from "../../components/tag-color";
import UploadButton from "../../components/upload-button";

import Avatar from "@mui/material/Avatar";
import DialogUi from "../../components/dialog";
import TextField from "@mui/material/TextField";
import { BorderRight } from "@mui/icons-material";

const CompanyList = () => {
  const [newName, setNewName] = useState("");
  const [newMarks, setNewMarks] = useState(0);

  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyFantasyName, setCompanyFantasyName] = useState("");
  const [companyPrimary, setCompanyPrimary] = useState("");
  const [companySecondary, setCompanySecondary] = useState("");
  const [companyForegroundPrimary, setCompanyForegroundPrimary] = useState("");
  const [companyForegroundSecondary, setCompanyForegroundSecondary] =
    useState("");

  const [companies, setcompanies] = useState<Company[]>([]);
  const companiesCollectionRef = collection(db, "companies");

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };
  const handleClickOpeEdit = (data: Company) => {
    setOpenEdit(true);
    setCompanyId(data.id);
    setCompanyName(data.name);
    setCompanyFantasyName(data.fantasyName);
    setCompanyPrimary(data.colors.primary);
    setCompanySecondary(data.colors.secondary);
    setCompanyForegroundPrimary(data.colors.foregroundPrimary);
    setCompanyForegroundSecondary(data.colors.foregroundSecondary);
  };
  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
    setCompanyId("");
    setCompanyName("");
    setCompanyFantasyName("");
    setCompanyPrimary("");
    setCompanySecondary("");
    setCompanyForegroundPrimary("");
    setCompanyForegroundSecondary("");
  };

  const handleEdit = () => {
    editCompany(companyId);
    handleClose();
  };
  const handleSuccess = () => {
    createCompany();
    handleClose();
  };

  const createCompany = async () => {
    await addDoc(companiesCollectionRef, {
      name: companyName,
      fantasyName: companyFantasyName,
      logo: null,
      colors: {
        foregroundPrimary: companyForegroundPrimary,
        foregroundSecondary: companyForegroundSecondary,
        primary: companyPrimary,
        secondary: companySecondary,
      },
    });
    getcompanies();
  };

  const updateLogo = async (id: string, photoUrl: string) => {
    const userDoc = doc(db, "companies", id);
    const newFields = { logo: photoUrl };
    await updateDoc(userDoc, newFields);
    getcompanies();
  };

  const editCompany = async (id: string) => {
    const userDoc = doc(db, "companies", id);
    const newFields = {
      name: companyName,
      fantasyName: companyFantasyName,
      logo: null,
      colors: {
        foregroundPrimary: companyForegroundPrimary,
        foregroundSecondary: companyForegroundSecondary,
        primary: companyPrimary,
        secondary: companySecondary,
      },
    };
    await updateDoc(userDoc, newFields);
    getcompanies();
  };
  const deleteUser = async (id: string) => {
    const userDoc = doc(db, "companies", id);
    await deleteDoc(userDoc);
    getcompanies();
  };

  const getcompanies = async () => {
    const data = await getDocs(companiesCollectionRef);
    setcompanies(
      data.docs.map((doc) => ({ ...(doc.data() as Company), id: doc.id }))
    );
  };

  useEffect(() => {
    getcompanies();
  }, []);

  const uploadFile = (userId: string, file: File) => {
    //By creating a reference to a file, your app gains access to it.
    const storageRef = ref(storage, "/images/companies/" + userId);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          // you keep uploaded img url
          updateLogo(userId, downloadedURL);
        });
      }
    );
  };

  const companyForm = () => {
    const stylesColorLabel = {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      alignItems: "center",
    };
    return (
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
          display: "flex",
          flexDirection: "column",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Company name"
          variant="outlined"
          value={companyName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCompanyName(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          label="Company fantasy name"
          variant="outlined"
          value={companyFantasyName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCompanyFantasyName(event.target.value);
          }}
        />

        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            display: "flex",
            flexDirection: "row",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="form"
            sx={{
              ...stylesColorLabel,
              border: "solid 0px rgba(0, 0, 0, 0.23)",
              borderRight: "solid 1px rgba(0, 0, 0, 0.23)",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="filled-basic"
              label="Primary color"
              variant="outlined"
              value={companyPrimary}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyPrimary(event.target.value);
              }}
            />
            <TagColor color={companyPrimary} />
          </Box>
          <Box
            component="form"
            sx={stylesColorLabel}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="filled-basic"
              label="Primary color contrast"
              variant="outlined"
              value={companyForegroundPrimary}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyForegroundPrimary(event.target.value);
              }}
            />
            <TagColor color={companyForegroundPrimary} />
          </Box>
        </Box>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            display: "flex",
            flexDirection: "row",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="form"
            sx={{
              ...stylesColorLabel,
              border: "solid 0px rgba(0, 0, 0, 0.23)",
              borderRight: "solid 1px rgba(0, 0, 0, 0.23)",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="filled-basic"
              label="Secondary color"
              variant="outlined"
              value={companySecondary}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanySecondary(event.target.value);
              }}
            />
            <TagColor color={companySecondary} />
          </Box>
          <Box
            component="form"
            sx={stylesColorLabel}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="filled-basic"
              label="Secondary color contrast"
              variant="outlined"
              value={companyForegroundSecondary}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyForegroundSecondary(event.target.value);
              }}
            />
            <TagColor color={companyForegroundSecondary} />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 2,
        padding: "48px",
        paddingTop: "80px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 2,
          paddingTop: "80px",
          width: "100%",
        }}
      >
        <Title>Companies</Title>

        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClickOpenCreate}
        >
          <AddIcon />
          Add company
        </Button>
      </Box>
      <DialogUi
        open={openCreate}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        component={companyForm()}
        title={"Add new company"}
        buttonLabel={"Add"}
      />
      <DialogUi
        open={openEdit}
        handleClose={handleClose}
        handleSuccess={handleEdit}
        component={companyForm()}
        title={"Edit company"}
        buttonLabel={"Save"}
      />
      <CollapsibleTable
        header={[
          "Name",
          "Fantasy name",
          "Logo",
          "Primary color",
          "Primary color contrast",
          "Secondary color",
          "Secondary color contrast",
          "",
          "",
        ]}
        rows={companies.map((company) => {
          return {
            cells: [
              company.name,
              company.fantasyName,
              <Avatar
                alt="Logo"
                src={company.logo}
                sx={{ width: 56, height: 56 }}
              />,
              <TagColor color={company.colors.primary} />,
              <TagColor color={company.colors.foregroundPrimary} />,
              <TagColor color={company.colors.secondary} />,
              <TagColor color={company.colors.foregroundSecondary} />,
              <UploadButton
                id={company.id}
                label={company.logo === null ? "Upload logo" : "Change logo"}
                uploadPhoto={uploadFile}
              />,
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleClickOpeEdit(company)}
              >
                Edit
              </Button>,
            ],
          };
        })}
      />
    </Box>
  );
};

export default CompanyList;
