import styled from "styled-components";

export const Tag = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 30px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cecece;
  box-shadow: 4px 4px 4px #cecece;
`;
