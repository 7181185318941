import styled from "styled-components";

export const Tag = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  padding: 4px 16px;
  width: fit-content;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
