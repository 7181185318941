import { ThemeProvider, createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import "./App.css";
import AppRouter from "./router";
import React from "react";
import Context from "./context";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00ABB3", // This is an orange looking color
      },
      secondary: {
        main: "#000", //Another orange-ish color
      },
    },
  });
  console.log("hehehe");
  return (
    <React.StrictMode>
      <Context>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </Context>
    </React.StrictMode>
  );
}

export default App;
