// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQcJEzjjeld6KD4kVov1pOIrz8zuv-YZs",
  authDomain: "hph-service.firebaseapp.com",
  projectId: "hph-service",
  storageBucket: "hph-service.appspot.com",
  messagingSenderId: "771606201680",
  appId: "1:771606201680:web:e6b6f1cc68ed6fe311d855",
  measurementId: "G-KGYYJ0YJW6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
