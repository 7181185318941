import { useState, useEffect, useContext } from "react";
import "../../App.css";
import { auth, db, storage } from "../../config/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Title from "../../components/title";
import CollapsibleTable from "../../components/collapsible-table";
import TagRole from "../../components/tag-role";
import Avatar from "@mui/material/Avatar";
import DialogUi from "../../components/dialog";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { UserContext } from "../../context";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";

function WorkerList() {
  const userWorker = useContext(UserContext);
  const [newName, setNewName] = useState("");
  const [newMarks, setNewMarks] = useState(0);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [workerId, setWorkerId] = useState("");
  const [workerName, setWorkerName] = useState("");
  const [workerEmail, setWorkerEmail] = useState("");
  const [workerPhone, setWorkerPhone] = useState("");
  const [workerRole, setWorkerRole] = useState("");
  const [workerCompanyId, setWorkerCompanyId] = useState("");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log((event.target as HTMLInputElement).value);
    setWorkerRole((event.target as HTMLInputElement).value);
  };
  const handleClickOpeEdit = (data: UserWorker) => {
    setOpenEdit(true);
    setWorkerId(data.id);
    setWorkerName(data.name);
    setWorkerPhone(data.phone);
    setWorkerRole(data.role);
    setWorkerEmail(data.email);
    setWorkerCompanyId(data.companyId);
  };
  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
    setWorkerId("");
    setWorkerName("");
    setWorkerPhone("");
    setWorkerRole("");
    setWorkerEmail("");
    setWorkerCompanyId("");
  };

  const editWorker = async (id: string) => {
    const userDoc = doc(db, "workers", id);
    const newFields = {
      name: workerName,
      email: workerEmail,
      phone: workerPhone,
      companyId: workerCompanyId,
      role: workerRole,
    };
    await updateDoc(userDoc, newFields);
    getUsers();
  };
  const handleEdit = () => {
    editWorker(workerId);
    handleClose();
  };
  const createWorker = async () => {
    await addDoc(usersCollectionRef, {
      name: workerName,
      email: workerEmail,
      firstAccess: true,
      phone: workerPhone,
      role: workerRole,
      companyId: workerCompanyId,
      photo: null,
    });

    createUserWithEmailAndPassword(auth, workerEmail, "hph123")
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    getUsers();
  };
  const handleSuccess = () => {
    createWorker();
    handleClose();
  };
  const handleClickOpenCreate = () => {
    setOpenCreate(true);
    getcompanies();
  };

  const [users, setUsers] = useState<UserWorker[]>([]);
  const usersCollectionRef = collection(db, "workers");
  const companiesCollectionRef = collection(db, "companies");
  const [companies, setCompanies] = useState<Company[]>([]);

  const getcompanies = async () => {
    const data = await getDocs(companiesCollectionRef);
    setCompanies(
      data.docs.map((doc) => ({ ...(doc.data() as Company), id: doc.id }))
    );

    if (userWorker.user?.role !== "master") {
      const companiesFilter = companies.filter(
        (company) => userWorker.user?.companyId === company.id
      );
      const userCompany =
        companiesFilter.length > 0 ? companiesFilter[0] : undefined;
      if (userCompany) setWorkerCompanyId(userCompany?.id);
    }
  };
  const updatePhoto = async (id: string, photoUrl: string) => {
    const userDoc = doc(db, "workers", id);
    const newFields = { photo: photoUrl };
    await updateDoc(userDoc, newFields);
    getUsers();
  };

  const deleteUser = async (id: string) => {
    const userDoc = doc(db, "workers", id);
    await deleteDoc(userDoc);
    getUsers();
  };

  const getUserCompany = (id: string) => {
    const companiesFilter = companies.filter((company) => id === company.id);
    const userCompany =
      companiesFilter.length > 0 ? companiesFilter[0] : undefined;
    if (userCompany) return userCompany?.fantasyName;

    return "";
  };
  const getUsers = async () => {
    const queryData = query(
      usersCollectionRef,
      where("companyId", "==", userWorker.user?.companyId)
    );

    const data = await getDocs(
      userWorker.user?.role === "master" ? usersCollectionRef : queryData
    );
    setUsers(
      data.docs.map((doc) => ({ ...(doc.data() as UserWorker), id: doc.id }))
    );
  };

  useEffect(() => {
    getUsers();
    getcompanies();
  }, []);
  const uploadFile = (userId: string, file: File) => {
    //By creating a reference to a file, your app gains access to it.
    const storageRef = ref(storage, "/images/workers/" + userId);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          // you keep uploaded img url
          updatePhoto(userId, downloadedURL);
        });
      }
    );
  };
  const handleChangeCompanyId = (event: SelectChangeEvent) => {
    setWorkerCompanyId(event.target.value as string);
  };

  const roleCanPerform = (role: string) => {
    const { user } = userWorker;
    if (user?.role === "master") {
      return ["owner", "maintainer", "worker"].includes(role);
    }
    if (user?.role === "owner") {
      return ["maintainer", "worker"].includes(role);
    }
    if (user?.role === "maintainer") {
      return ["worker"].includes(role);
    }
    return false;
  };

  const workerForm = () => {
    const stylesColorLabel = {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      alignItems: "center",
    };

    const roles = ["master", "owner", "maintainer", "worker"].filter((role) => {
      const { user } = userWorker;
      if (user?.role === "master") {
        return ["owner", "maintainer", "worker"].includes(role);
      }
      if (user?.role === "owner") {
        return ["maintainer", "worker"].includes(role);
      }
      if (user?.role === "maintainer") {
        return ["worker"].includes(role);
      }
      return false;
    });

    const companiesFilter = companies.filter(
      (company) => userWorker.user?.companyId === company.id
    );
    const userCompany =
      companiesFilter.length > 0 ? companiesFilter[0] : undefined;

    return (
      <Box
        component="form"
        sx={{
          minWidth: "500px",
          minHeight: "3ls00px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          paddingTop: "12px",
        }}
        noValidate
        autoComplete="off"
      >
        {userWorker.user?.role === "master" ? (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Worker company
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={workerCompanyId}
              label="Worker company"
              onChange={handleChangeCompanyId}
            >
              {companies.map((company) => (
                <MenuItem value={company.id}>{company.fantasyName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          "Company: " + userCompany?.fantasyName
        )}
        <TextField
          id="outlined-basic"
          label="Worker name"
          variant="outlined"
          value={workerName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkerName(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          label="Worker email"
          variant="outlined"
          value={workerEmail}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkerEmail(event.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          label="Worker phone"
          variant="outlined"
          value={workerPhone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkerPhone(event.target.value);
          }}
        />
        <RadioGroup
          row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={workerRole}
          onChange={handleRadioChange}
        >
          {roles.map((role) => (
            <FormControlLabel
              value={role}
              control={<Radio />}
              label={<TagRole label={role}></TagRole>}
            />
          ))}
        </RadioGroup>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 2,
        padding: "48px",
        paddingTop: "80px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 2,
          paddingTop: "80px",
          width: "100%",
        }}
      >
        <Title>Workers</Title>

        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClickOpenCreate}
        >
          <AddIcon />
          Add worker
        </Button>
      </Box>
      <DialogUi
        open={openCreate}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        component={workerForm()}
        title={"Add new worker"}
        buttonLabel={"Add"}
      />
      <DialogUi
        open={openEdit}
        handleClose={handleClose}
        handleSuccess={handleEdit}
        component={workerForm()}
        title={"Edit worker"}
        buttonLabel={"Save"}
      />
      <CollapsibleTable
        header={["Name", "Phone", "Role", "Photo", "Company", "", ""]}
        rows={users.map((user) => {
          return {
            cells: [
              user.name,
              user.phone,
              <TagRole label={user.role}></TagRole>,
              <Avatar
                alt="Logo"
                src={user.photo}
                sx={{ width: 56, height: 56 }}
              />,
              getUserCompany(user.companyId),
              roleCanPerform(user.role) ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClickOpeEdit(user)}
                >
                  Edit
                </Button>
              ) : (
                <div />
              ),
              roleCanPerform(user.role) ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => deleteUser(user.id)}
                >
                  Delete
                </Button>
              ) : (
                <></>
              ),
            ],
          };
        })}
      />
    </Box>
  );
}

export default WorkerList;
