import { useState, useEffect, useContext } from "react";
import "../../App.css";
import { db, storage } from "../../config/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Title from "../../components/title";
import CollapsibleTable from "../../components/collapsible-table";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TagColor from "../../components/tag-color";
import UploadButton from "../../components/upload-button";
import Avatar from "@mui/material/Avatar";
import DialogUi from "../../components/dialog";
import TextField from "@mui/material/TextField";
import { UserContext } from "../../context";

const initialClientState = {
  id: "",
  accountsPhoneNumber: "",
  address: {
    description: "",
    //geolocation: "",
  },
  email: "",
  name: "",
  purchasePhoneNumber: "",
  logo: "",
};

const ClientList = () => {
  const userWorker = useContext(UserContext);
  const [clients, setClients] = useState<Client[]>([]);
  const [currentClient, setCurrentClient] =
    useState<Client>(initialClientState);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const clientsCollectionRef = collection(db, "clients");

  const handleClickOpenCreate = () => setOpenCreate(true);
  const handleClickOpenEdit = (data: Client) => {
    setOpenEdit(true);
    setCurrentClient(data);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
    setCurrentClient(initialClientState);
  };

  const handleEdit = async () => {
    await editClient(currentClient.id);
    handleClose();
  };

  const handleSuccess = async () => {
    await createClient();
    handleClose();
  };

  const createClient = async () => {
    await addDoc(clientsCollectionRef, {
      ...currentClient,
      companyId: userWorker.user?.companyId,
      logo: null,
    });
    fetchClients();
  };

  const editClient = async (id: string) => {
    const clientDoc = doc(db, "clients", id);
    await updateDoc(clientDoc, currentClient);
    fetchClients();
  };

  const deleteClient = async (id: string) => {
    const clientDoc = doc(db, "clients", id);
    await deleteDoc(clientDoc);
    fetchClients();
  };

  const fetchClients = async () => {
    const data = await getDocs(clientsCollectionRef);
    setClients(
      data.docs.map((doc) => ({ ...(doc.data() as Client), id: doc.id }))
    );
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const uploadFile = (userId: string, file: File) => {
    const storageRef = ref(storage, `/images/clients/${userId}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateLogo(userId, downloadURL);
        });
      }
    );
  };

  const updateLogo = async (id: string, photoUrl: string) => {
    const clientDoc = doc(db, "clients", id);
    await updateDoc(clientDoc, { logo: photoUrl });
    fetchClients();
  };

  const renderClientForm = () => (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
        display: "flex",
        flexDirection: "column",
        width: "500px",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label="Client Name"
        variant="outlined"
        value={currentClient.name}
        onChange={(e) =>
          setCurrentClient({ ...currentClient, name: e.target.value })
        }
      />
      <TextField
        label="Client Email"
        variant="outlined"
        value={currentClient.email}
        onChange={(e) =>
          setCurrentClient({ ...currentClient, email: e.target.value })
        }
      />
      <TextField
        label="Accounts Phone Number"
        variant="outlined"
        value={currentClient.accountsPhoneNumber}
        onChange={(e) =>
          setCurrentClient({
            ...currentClient,
            accountsPhoneNumber: e.target.value,
          })
        }
      />
      <TextField
        label="Purchase Phone Number"
        variant="outlined"
        value={currentClient.purchasePhoneNumber}
        onChange={(e) =>
          setCurrentClient({
            ...currentClient,
            purchasePhoneNumber: e.target.value,
          })
        }
      />
      <TextField
        label="Address"
        variant="outlined"
        value={currentClient.address.description}
        onChange={(e) =>
          setCurrentClient({
            ...currentClient,
            address: { ...currentClient.address, description: e.target.value },
          })
        }
      />
    </Box>
  );

  return (
    <Box sx={{ padding: "48px", paddingTop: "160px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "16px",
        }}
      >
        <Title>Clients</Title>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenCreate}
        >
          <AddIcon /> Add client
        </Button>
      </Box>
      <DialogUi
        open={openCreate}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        component={renderClientForm()}
        title="Add New Client"
        buttonLabel="Add"
      />
      <DialogUi
        open={openEdit}
        handleClose={handleClose}
        handleSuccess={handleEdit}
        component={renderClientForm()}
        title="Edit Client"
        buttonLabel="Save"
      />
      <CollapsibleTable
        header={[
          "Name",
          "Email",
          "Accounts Phone",
          "Purchase Phone",
          "Address Description",
          "Logo",
          "",
          "",
        ]}
        rows={clients.map((client) => ({
          cells: [
            client.name,
            client.email,
            client.accountsPhoneNumber,
            client.purchasePhoneNumber,
            client.address.description,
            // client.address.geolocation,
            <Avatar
              alt="Logo"
              src={client.logo}
              sx={{ width: 56, height: 56 }}
            />,
            <UploadButton
              id={client.id}
              label={client.logo === null ? "Upload logo" : "Change logo"}
              uploadPhoto={uploadFile}
            />,
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleClickOpenEdit(client)}
            >
              Edit
            </Button>,
          ],
        }))}
      />
    </Box>
  );
};

export default ClientList;
