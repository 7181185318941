import { Tag } from "./styles";

const TagRole = ({ label }: { label: string }) => {
  const colors: Record<string, string> = {
    master: "#95d7b0",
    owner: "#afa2cb",
    maintainer: "#ebb181",
    worker: "#9cdce6",
  };

  const color = colors[label];
  return <Tag color={color}>{label}</Tag>;
};
export default TagRole;
