import { ChangeEvent, useEffect, useState } from "react";
import { Button, styled } from "@mui/material";
import userLogo from "../../assets/images/user.png";

export default function UploadButton({
  id,
  uploadPhoto,
  label,
}: {
  id: string;
  label: string;
  uploadPhoto: (userId: string, image: File) => void;
}) {
  const [image, setImage] = useState<File>();
  const onChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      if (image) uploadPhoto(id, e.target.files[0]);
      setImage(e.target.files[0]);
    }
    console.log(e.target.files);
  };
  useEffect(() => {
    console.log("picture: ", image);
  }, [image]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <Button
      size="small"
      color="primary"
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
    >
      {label}
      <VisuallyHiddenInput type="file" onChange={(e) => onChangeImage(e)} />
    </Button>
  );
}
