import { useState, useEffect } from "react";
import "../../App.css";
import { db, storage } from "../../config/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import Title from "../../components/title";
import CollapsibleTable from "../../components/collapsible-table";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DialogUi from "../../components/dialog";
import TextField from "@mui/material/TextField";
import UploadButton from "../../components/upload-button";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Interface para representar uma ServiceOrder

// Estado inicial para uma nova ServiceOrder
const initialServiceOrderState: ServiceOrder = {
  id: "",
  clientId: "",
  clientName: "",
  contactPhone: "",
  createdAt: "",
  description: "",
  hasPriority: false,
  photos: [],
  siteContactName: "",
  siteContactPhoneNumber: "",
  statusList: [],
  workerId: "",
};

const ServiceOrderList = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [serviceOrders, setServiceOrders] = useState<ServiceOrder[]>([]);
  const [currentServiceOrder, setCurrentServiceOrder] = useState<ServiceOrder>(
    initialServiceOrderState
  );
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const clientsCollectionRef = collection(db, "clients");
  const serviceOrdersCollectionRef = collection(db, "service-orders");

  const handleClickOpenCreate = () => setOpenCreate(true);
  const handleClickOpenEdit = (data: ServiceOrder) => {
    setOpenEdit(true);
    setCurrentServiceOrder(data);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
    setCurrentServiceOrder(initialServiceOrderState);
  };

  const handleEdit = async () => {
    await editServiceOrder(currentServiceOrder.id);
    handleClose();
  };

  const handleSuccess = async () => {
    await createServiceOrder();
    handleClose();
  };

  const createServiceOrder = async () => {
    await addDoc(serviceOrdersCollectionRef, {
      ...currentServiceOrder,
      photos: [], // Initial empty array for photos
    });
    fetchServiceOrders();
  };

  const editServiceOrder = async (id: string) => {
    const serviceOrderDoc = doc(db, "service-orders", id);
    await updateDoc(serviceOrderDoc, currentServiceOrder);
    fetchServiceOrders();
  };

  const deleteServiceOrder = async (id: string) => {
    const serviceOrderDoc = doc(db, "service-orders", id);
    await deleteDoc(serviceOrderDoc);
    fetchServiceOrders();
  };

  const fetchServiceOrders = async () => {
    const data = await getDocs(serviceOrdersCollectionRef);
    setServiceOrders(
      data.docs.map((doc) => ({ ...doc.data(), id: doc.id } as ServiceOrder))
    );
  };

  const fetchClients = async () => {
    const data = await getDocs(clientsCollectionRef);
    setClients(
      data.docs.map((doc) => ({ ...(doc.data() as Client), id: doc.id }))
    );
  };

  useEffect(() => {
    fetchClients();
    fetchServiceOrders();
  }, []);

  const uploadFile = (orderId: string, file: File) => {
    const serviceOrder = serviceOrders.filter(
      (order) => order.id === orderId
    )[0];
    const photos = serviceOrder.photos || [];
    const storageRef = ref(storage, `/images/serviceOrders/${photos.length}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updatePhotos(orderId, downloadURL, photos);
        });
      }
    );
  };

  const updatePhotos = async (
    id: string,
    photoUrl: string,
    photos: string[]
  ) => {
    const serviceOrderDoc = doc(db, "service-orders", id);
    const updatedPhotos = [...photos, photoUrl];
    await updateDoc(serviceOrderDoc, { photos: updatedPhotos });
    fetchServiceOrders();
  };

  const renderServiceOrderForm = () => (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
        display: "flex",
        flexDirection: "column",
        width: "500px",
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Client</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentServiceOrder.clientId}
          label="Client"
          onChange={(event: SelectChangeEvent) => {
            const clientSelected = clients.filter(
              (client) => client.id === (event.target.value as string)
            )[0];
            setCurrentServiceOrder({
              ...currentServiceOrder,
              clientId: clientSelected.id,
              clientName: clientSelected.name,
              contactPhone: clientSelected.accountsPhoneNumber,
              siteContactPhoneNumber: clientSelected.purchasePhoneNumber,
              siteContactName: clientSelected.address.description,
            });
          }}
        >
          {clients.map((client) => (
            <MenuItem value={client.id}>{client.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Address"
        variant="outlined"
        disabled
        value={currentServiceOrder.siteContactName}
      />
      <TextareaAutosize
        placeholder="Description"
        minRows={4}
        value={currentServiceOrder.description}
        onChange={(e: { target: { value: any } }) =>
          setCurrentServiceOrder({
            ...currentServiceOrder,
            description: e.target.value,
          })
        }
      />
    </Box>
  );

  return (
    <Box sx={{ padding: "48px", paddingTop: "160px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "16px",
        }}
      >
        <Title>Service Orders</Title>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenCreate}
        >
          <AddIcon /> Add Service Order
        </Button>
      </Box>
      <DialogUi
        open={openCreate}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        component={renderServiceOrderForm()}
        title="Add New Service Order"
        buttonLabel="Add"
      />
      <DialogUi
        open={openEdit}
        handleClose={handleClose}
        handleSuccess={handleEdit}
        component={renderServiceOrderForm()}
        title="Edit Service Order"
        buttonLabel="Save"
      />
      <CollapsibleTable
        header={[
          "Client Name",
          "Contact Phone",
          "Description",
          "Site Contact Name",
          "Site Contact Phone",
          "Photos",
          "",
          "",
          "",
        ]}
        rows={serviceOrders.map((order) => ({
          cells: [
            order.clientName,
            order.contactPhone,
            order.description,
            order.siteContactName,
            order.siteContactPhoneNumber,
            <Badge badgeContent={order.photos.length} color="primary" showZero>
              <InsertPhotoIcon color="action" />
            </Badge>,
            <UploadButton
              id={order.id}
              label={
                order.photos.length === 0 ? "Upload photos" : "Add more photos"
              }
              uploadPhoto={uploadFile}
            />,
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleClickOpenEdit(order)}
            >
              Edit
            </Button>,
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => deleteServiceOrder(order.id)}
            >
              Delete
            </Button>,
          ],
        }))}
      />
    </Box>
  );
};

export default ServiceOrderList;
