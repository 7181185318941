import { useState, createContext, PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";

export const UserContext = createContext<{
  user?: UserWorker;
  setUser?: (user?: UserWorker) => void;
}>({});

const Context = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<UserWorker>();

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
export default Context;
