import { useState, ChangeEvent } from "react";
import { db } from "../../config/firebase-config";
import { collection, addDoc } from "firebase/firestore";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";

interface InventoryItem {
  name: string;
  amountOfItems: number;
  costPerItem: number;
  pricePerItem: number;
}

const InventoryUpload = ({ onSuccess }: { onSuccess: () => Promise<void> }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inventoryCollectionRef = collection(db, "inventory");

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileRead = async () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData: InventoryItem[] = XLSX.utils.sheet_to_json(sheet);

          for (let item of jsonData) {
            await addDoc(inventoryCollectionRef, {
              name: item.name,
              amountOfItems: item.amountOfItems,
              costPerItem: item.costPerItem,
              pricePerItem: item.pricePerItem,
            });
          }
          onSuccess();
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      <Button variant="contained" color="primary" onClick={handleFileRead}>
        Upload Inventory
      </Button>
    </div>
  );
};

export default InventoryUpload;
