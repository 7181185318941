import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface DialogUiProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  component?: React.ReactElement;
  title: string;
  buttonLabel: string;
}

const DialogUi = ({
  open,
  handleClose,
  handleSuccess,
  component,
  title,
  buttonLabel,
}: DialogUiProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>{component}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSuccess} autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DialogUi;
